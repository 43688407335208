<!--  -->
<template>
    <div>
        <head-layout :head-btn-options="headBtnOptions" head-title="应用列表新增" @head-cancel="cancelFn"
            @head-save="saveFn('save')" @head-saveback='saveFn("back")'>
        </head-layout>
        <div style="background: #fff; padding: 20px"></div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">


            <el-row>
                <el-col :span="8">
                    <el-form-item label="应用编码" prop="appNo">
                        <el-input v-model="ruleForm.appNo"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="应用名称" prop="appName">
                        <el-input v-model="ruleForm.appName"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>


            <el-dialog title="指标" :visible.sync="dialogTableVisible">
                <el-input
  placeholder="输入关键字进行过滤"
  v-model="filterText">
</el-input>


                <el-tree :data="treedata" show-checkbox node-key="xmbh" ref="tree" :props="defaultProps" :filter-node-method="filterNode">
                </el-tree>


                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogTableVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addtable">确 定</el-button>
                </div>
            </el-dialog>



            <el-button @click="addList">添加</el-button>
            <el-table :data="ruleForm.xmmcList" style="width: 100%;margin-bottom: 20px;" row-key="xmmc" border
                default-expand-all :tree-props="{ children: 'childrenList', hasChildren: 'hasChildren' }">
                <el-table-column label="类别" prop="lb">

                </el-table-column>
                <el-table-column prop="xmmc" label="名称">
                </el-table-column>
                <el-table-column label="别名">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.xmAlias" v-show="scope.row.childrenList.length == 0"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="星" width="180">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.starState" active-text="是" inactive-text="否" active-value="1"
                            inactive-value="0" v-show="scope.row.childrenList.length == 0" @change="changeStar(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="及格基线">
                    <template slot-scope="scope">
                        <el-input-number style="width: 100%;" :controls="false" v-model="scope.row.red" :precision="2"
                            :min="0" v-show="scope.row.childrenList.length == 0"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="优秀基线">
                    <template slot-scope="scope">
                        <el-input-number style="width: 100%;" :controls="false" v-model="scope.row.green" :precision="2"
                            :min="0" v-show="scope.row.childrenList.length == 0"></el-input-number>
                    </template>
                </el-table-column>

                <el-table-column label="排序">
                    <template slot-scope="scope">
                        <el-input-number style="width: 100%;" :controls="false" v-model="scope.row.appSort" :precision="0"
                            :min="0" v-show="scope.row.childrenList.length == 0"></el-input-number>
                    </template>
                </el-table-column>

                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" @click="addList" v-show="scope.row.childrenList.length == 0">添加删除</el-button>
                    </template>
                </el-table-column>



            </el-table>

        </el-form>
    </div>
</template>

<script>
import { SeeZBFX, dataApisTree, SaveZBset, dataApisTree2 } from "@/api/dataAcquisition/index";
import CommonTree from "@/views/components/com_tree/index"

export default {
    data() {
        return {
            dialogTableVisible: false,
            headBtnOptions: [
                {
                    label: this.$t("cip.cmn.btn.saveBtn"),
                    emit: "head-save",
                    type: "button",
                    icon: "",
                },
                {
                    label: this.$t('cip.cmn.btn.saveBackBtn'),
                    emit: "head-saveback",
                    type: "button",
                    icon: "",
                },
                {
                    label: this.$t("cip.cmn.btn.celBtn"),
                    emit: "head-cancel",
                    type: "button",
                    icon: "",
                },
            ],
            ruleForm: {
                appNo: "",
                appName: "",
                // red: undefined,
                // green: undefined,
                // appSort: undefined,
                xmmcList: []
            },
            rules: {
                appNo: [
                    { required: true, message: '请输入应用编码', trigger: 'blur' },
                ],
                appName: [
                    { required: true, message: '请输入应用名称', trigger: 'blur' },
                ],

            },
            treedata: [],
            filterText: '',
            defaultProps: {
                children: 'childrenList',
                label: 'xmmc'
            },
            treekey: 'xmbh',
            searchTitle: 'xmbh',
            tableList: [],
            CData: [],
            one: 1
        };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    components: {
        CommonTree,

    },
    computed: {},

    mounted() {
        // this.MdataApisTree()
        if (this.$route.query.type == "see") {
            let id = this.$route.query.id
            this.getDetail(id)
        }
        if (this.$route.query.type == "add") {
            this.MdataApisTree2()
        }


    },

    methods: {
        filterNode(value, treedata) {
        if (!value) return true;
        return treedata.xmmc.indexOf(value) !== -1;
      },
        changeStar(e){
            let aa = []
          let b1 =  JSON.parse(JSON.stringify(this.ruleForm.xmmcList))
            let bb = this.flatten(b1)
            bb.forEach(element => {
                if(element.starState==1){
                    aa.push(element) 
                }
            });
            // if(aa.length>6){
            //     e.starState = 0
          
            //     this.$message({
            //                     message: "星项目最多为6条",
            //                     type: "warning",
            //                 });
            // }
        },
        addList() {
            this.dialogTableVisible = true
            this.$nextTick(() => {
                if (this.$route.query.type == "see" && this.one == 1) {
                    let Check = []
                    let oldList = JSON.parse(JSON.stringify(this.ruleForm.xmmcList))
                    let aa = this.flatten(oldList)
                    aa.forEach(element => {
                        Check.push(element.xmbh)
                    });



                    aa = aa.filter(item => {
                        return item.xmbh != "";
                    });
                    let alltree = this.flatten(JSON.parse(JSON.stringify(this.treedata)))
                    let bb = []

                    alltree.forEach(item => {
                        aa.forEach(item2 => {
                            if (item.xmbh == item2.xmbh) {
                                bb.push(item.xmbh)
                            }
                        });
                    });

                    setTimeout(() => {
                        this.$refs.tree.setCheckedKeys(bb)
                    }, 100);
                    this.one++

                }


            })





        },

        DelList() {

        },
        diguiB(arr) {
            arr.forEach((item, index, arr) => {

                let obj = item
                this.CData.push(obj)
                if (item.childrenList instanceof Array) { // 有children数组
                    this.diguiB(item.childrenList)
                }
            });
        },

        diguiA(arr) {
            arr.forEach((item, index, arr) => {

                for (let i = 0; i < this.CData.length; i++) {
                    if (item.xmbh == this.CData[i].xmbh) {
                        item.green = this.CData[i].green
                        item.red = this.CData[i].red
                        item.appSort = this.CData[i].appSort
                        item.starState = this.CData[i].starState
                        item.xmAlias = this.CData[i].xmAlias
                        
                        
                    }
                }

                if (item.childrenList instanceof Array) { // 有children数组
                    this.diguiA(item.childrenList)
                }

            });
        },



        addtable() {

            let oldList = JSON.parse(JSON.stringify(this.ruleForm.xmmcList))
            let tree = JSON.parse(JSON.stringify(this.treedata))
            let allList = this.flatten(tree)
            let allList2 = []
            allList.forEach(element => {
                allList2.push(element.xmbh)
            });

            let allCheck = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
            console.log(allCheck, "all");
            let newArr = allList2.filter((item) => !allCheck.some((ele) => ele === item));
            let tree2 = JSON.parse(JSON.stringify(this.treedata))
            if (newArr) {
                newArr.forEach(element => {
                    this.removeNodeInTree(tree2, element)
                });
            }

            this.ruleForm.xmmcList = tree2
            this.diguiB(oldList)
            this.diguiA(this.ruleForm.xmmcList)


            this.ruleForm.xmmcList = this.ruleForm.xmmcList.filter(item => {
                return item.childrenList.length>0;
            });
            this.dialogTableVisible = false
        },
        getDetail(id) {

            SeeZBFX({ id: id }).then(res => {

                dataApisTree2().then(res2 => {
                    this.treedata = res2.data.data
                    this.ruleForm = res.data.data
                  let tree1 =  JSON.parse(JSON.stringify(this.treedata))
                  tree1.forEach(element => {
                    element.childrenList =[]
                  });
                  let tree2 =  JSON.parse(JSON.stringify(this.ruleForm.xmmcList))
                  tree1.forEach(element => {
                    tree2.forEach(element2 => {
                         if(element.xmmc==element2.lb){
                            element.childrenList.push(element2)
                         }
                    });
                   
                  });

                
                  tree1 = tree1.filter(item => {
                return item.childrenList.length>0;
            });
             
            this.ruleForm.xmmcList = tree1
            


                })




            })
        },
        
        //班组tree
        MdataApisTree() {
            dataApisTree().then(res => {
                this.treedata = res.data.data


            })
        },

        MdataApisTree2() {
            dataApisTree2().then(res => {
                this.treedata = res.data.data


            })
        },



        removeNodeInTree(treeList, id) { // 通过id从数组（树结构）中移除元素
            if (!treeList || !treeList.length) {
                return
            }
            for (let i = 0; i < treeList.length; i++) {
                if (treeList[i].xmbh === id) {
                    treeList.splice(i, 1);
                    break;
                }
                this.removeNodeInTree(treeList[i].childrenList, id)
            }
        },
        //扁平化 树
        flatten(arr) {
            let result = [];
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].childrenList && arr[i].childrenList.length > 0) {
                    result = result.concat(this.flatten(arr[i].childrenList));
                    arr[i].childrenList = []
                    result = result.concat(arr[i])
                } else {
                    result.push(arr[i]);
                }
            }
            return result;
        },
        saveFn(e) {

            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
          
                    let oldList = JSON.parse(JSON.stringify(this.ruleForm.xmmcList))
            let allList = this.flatten(oldList)
           let starnum = []
            allList.forEach(element => {
                if(element.starState==1){
                    starnum.push(element) 
                }
            });
            if(starnum.length<3){
                this.$message({
                                message: "星数量必须大于3条！",
                                type: "warning",
                            });
            }else{
                if (this.$route.query.type == "add") {
                        SaveZBset(this.ruleForm).then(res => {
                            this.$message({
                                message: "保存成功！",
                                type: "success",
                            });
                            if (e == 'back') {
                                this.$router.$avueRouter.closeTag();
                                this.$router.back();

                            }
                        })
                    }
                    if (this.$route.query.type == "see") {
                        SaveZBset(this.ruleForm).then(res => {
                            this.$message({
                                message: "保存成功！",
                                type: "success",
                            });
                            if (e == 'back') {
                                this.$router.$avueRouter.closeTag();
                                this.$router.back();

                            }
                        })
                    }
            }
                    
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });





        },
        cancelFn(){
            this.$router.$avueRouter.closeTag();
                                this.$router.back();
        },
    }
}

</script>
<style lang='less' scoped></style>